import React from 'react'
import Layout from '../component/layout'
// import Title from '../component/typography/title'
// import SubTitle from '../component/typography/subtitle'
import PageTitle from "../component/typography/pageTitle"
import JourneyOmnidya from "../component/journeyOmnidya"

const ComingSoon = () => {
  return(
    <Layout page="" title="Coming Soon">
      <div className="container-xxl comingsoon-bg">
        <div className="white-trans">
        </div>
        <div className="row justify-content-center text-center">
          <div className="col col-lg-5">
            <PageTitle title="Coming Soon" className="pt-5" />
            <div className="footer-title">Sign up and we'll spill the tea... aka our newsletter</div>
            <div className="subscribe-form p-2 my-3">
              <form action="https://cl.s11.exct.net/subscribe.aspx?lid=610" name="subscribeForm" method="post" onSubmit="return checkForm();"> 
                <input type="hidden" name="thx" value="https://www.omnidya.com" /> {/* Thank You Page Details here */}
                <input type="hidden" name="err" value="https://www.omnidya.com" /> {/* Error Page Details here */}
                <input type="hidden" name="MID" value="514010013" /> {/* Do not change this */}
                <div class="row">
                  <div class="col-lg-7 col-sm-12">
                    <input type="text" name="Email Address" class="form-control border-0" placeholder="example@email.com" />
                  </div>
                  <div class="col-lg-5 col-sm-12 mt-xs-2">
                    <button type="submit" name="submit" class="btn btn-block btn-gradient">Get a Quote</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <JourneyOmnidya gutter />
    </Layout>
  )
}

export default ComingSoon